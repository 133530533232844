import React from 'react'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'



// get the page details for 'start'



const StartPage = (props) => {
    const {data, errors} = props

    //const page = (data || {}).site
    return (
        <Layout>
          <SEO
            //title={page.title}
            title="Start Here"
            //description={page.description}
            description=""
            //keywords={page.keywords}
            keywords=""
          />
          <Container>
            <div>
                <h1>Essential Snacks Guide</h1>

                We put together a list of essential healthy snacks you need to have on hand. Use this list to stock up or learn more about the benefits.
                
                <ol>
                    <li>Nuts</li>
                    <li>Juice</li>
                    <li>Granola</li>
                    <li>Fruit</li>
                    <li>Pickles</li>
                    <li>Crackers</li>
                    <li>Rasins</li>
                </ol>

            </div>
          </Container>
        </Layout>
    )
}


export default StartPage